<template>
  <div class="col-12 col-lg-6 c">
    <div class="card">
        <div class="card-header">
            <h5>
                <i class="fa fa-cogs"></i>
                إعدادات غياب الحصص
            </h5>
        </div>
        <div class="card-body">
            <div class="alert alert-warning text-center g">
                <strong class="text-dark">
                    <i class="fa fa-info-circle"></i>
                    ربط تحضير الحصص باليوم يتيح لك تحضير وتغيب الطلاب اعتماداً على تحضير الحصص.<br>
                    اذا حضر الطالب في الحصص يعتبر حاضر في التقرير اليومي،<br>
                    واذا غاب يعتبر غائب في التقرير اليومي.<br>
                    واذا تأخر يعتبر متأخر في التقرير اليومي.
                </strong>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="enable_connect_lesson_with_day">
                <strong style="position: relative; top: 3px; cursor: pointer">
                    <h5>
                        تفعيل ربط تحضير الحصص باليوم
                    </h5>
                </strong>
              </label>
            </div>
            <template v-if="enable_connect_lesson_with_day">
                <hr>
                <div class="form-group">
                  <h5 for="">الحصة المراد اعتمادها للحضور او الغياب</h5>
                  <select class="form-control" v-model="connect_lesson_with_day_lesson">
                    <option value="1">الأولى</option>
                    <option value="2">الثانية</option>
                  </select>
                </div>
            </template>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="save()">
                    <i class="fa fa-check"></i>
                    حفظ الإعدادات
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import templates from '../pages/templates.vue'
export default {
  components: { templates },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            enable_connect_lesson_with_day: false,
            connect_lesson_with_day_lesson: "1"
        }
    },
    created(){
        if(!checkPer("lessons")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/lessons/settings', {
            jwt: g.user.jwt,
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response, 200)
            }else{
                g.enable_connect_lesson_with_day = r.response.enable_connect_lesson_with_day;
                g.connect_lesson_with_day_lesson = r.response.connect_lesson_with_day_lesson;
            }
        }).fail(function(){
        })
    },
    methods: {
        save(){
             var g = this;
            $.post(api + '/user/lessons/settings/save', {
                jwt: g.user.jwt,
                enable_connect_lesson_with_day: this.enable_connect_lesson_with_day,
                connect_lesson_with_day_lesson :this.connect_lesson_with_day_lesson
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم الحفظ بنجاح", 100)
                }
            }).fail(function(){
            })
        }
    }
}
</script>

<style>

</style>